import React, { Suspense, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { Backdrop, CircularProgress, CssBaseline, NoSsr, ThemeProvider as MuiThemeProvider } from '@material-ui/core'
import { LocalizationProvider } from '@material-ui/pickers'
import { LanguageProvider } from '@opus/web.core.hooks.use-translation'
import { AppRoute } from './app.route'
import { Router } from 'react-router-dom'
import { appStore, authStore, history } from '~/stores'
import { loadingState, observer } from '~/common/mobx.decorator'
import { GlobalStyle, useStyles } from './app.style'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from '~/common/apollo'
import { THEMES } from '~/themes'
import { SnackbarProvider } from 'notistack'
import { GlobalNotify } from '~/components/notify'
import dateAdapter from '@material-ui/pickers/adapter/moment'
import moment from 'moment'
import { ACTIONS } from './common/constants'
import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import LanguagePlural from 'i18next-intervalplural-postprocessor'
import { initReactI18next } from 'react-i18next'
import { setLocale } from 'yup'
import { CommonChangePasswordDialogFeature } from '~/features/common-change-password/common-change-password-dialog.feature'
import { CoreProvider } from '~/core/providers'
import { auditService, authService, dataService, permissionService } from '~/services'
import { useInterval } from 'ahooks'

i18next
	.use(Backend)
	.use(LanguageDetector)
	.use(LanguagePlural)
	.use(initReactI18next) // bind react-i18next to the instance
	.init(
		{
			fallbackLng: 'en',
			lng: 'en',
			defaultNS: 'translation',
			fallbackNS: 'translation',

			interpolation: {
				escapeValue: false, // not needed for react!!
			},
			backend: {
				loadPath: '/locales/{{lng}}/{{ns}}.json',
				addPath: '/locales/add/{{lng}}/{{ns}}',
				queryStringParams: { version: process.env.REACT_APP_CI_COMMIT_TAG },
			},
		},
		(_, t) => {
			const locale = t('$VALIDATION', { returnObjects: true })
			setLocale(locale)
		}
	)

moment.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: 'a few seconds',
		ss: '%d seconds',
		m: '1 minute',
		mm: '%d minutes',
		h: '1 hour',
		hh: '%d hours',
		d: '1 day',
		dd: '%d days',
		w: '1 week',
		ww: '%d weeks',
		M: '1 month',
		MM: '%d months',
		y: '1 year',
		yy: '%d years',
	},
})

export const App = observer(() => {
	const loading = loadingState(ACTIONS.appStore.init)
	const classes = useStyles()
	const { ready, expiresAt, refreshAuthToken } = authStore
	const isRefreshingToken = loadingState('authStore/refreshAuthToken')

	useEffect(() => {
		if (!ready) {
			return
		}
		void appStore.init()
	}, [ready])

	useInterval(() => {
		if (expiresAt === 0 || expiresAt - Date.now() > 0 || isRefreshingToken) {
			return
		}
		void refreshAuthToken()
	}, 2000)

	const themeInit = THEMES.candidate

	return (
		<CoreProvider authService={authService} dataService={dataService} auditService={auditService} permissionService={permissionService}>
			<Router history={history}>
				<Suspense
					fallback={
						<Backdrop open={true}>
							<CircularProgress />
						</Backdrop>
					}
				>
					<NoSsr>
						<ApolloProvider client={apolloClient}>
							<LanguageProvider value="care">
								<MuiThemeProvider theme={themeInit}>
									<ThemeProvider theme={themeInit}>
										<SnackbarProvider
											maxSnack={3}
											preventDuplicate
											anchorOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}
											classes={{ root: classes.root }}
										>
											<LocalizationProvider dateAdapter={dateAdapter}>
												<CssBaseline />
												<GlobalStyle />
												<GlobalNotify />
												{/*{loadingStore.global && !routerStore.isEmbedded && <LinearProgress css={linearProgressStyle} />}*/}
												{!loading && ready && <AppRoute />}
												{/*<OtpDialogComponent />*/}
												<CommonChangePasswordDialogFeature
													showChangePasswordDialg={authStore.showChangePasswordDialog}
													setShowChangePasswordDialog={authStore.setShowChangePasswordDialog}
												/>
											</LocalizationProvider>
										</SnackbarProvider>
									</ThemeProvider>
								</MuiThemeProvider>
							</LanguageProvider>
						</ApolloProvider>
					</NoSsr>
				</Suspense>
			</Router>
		</CoreProvider>
	)
})
