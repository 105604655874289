import { gql } from '@apollo/client'
import {
	DIRECT_UPLOAD_FRAGMENT,
	IMAGE_FILE_FRAGMENT,
	JOB_DETAIL_FRAGMENT,
	WORK_EXPERIENCE_FRAGMENT,
	WORKED_TIMESHEET_FRAGMENT,
	WORKER_CERTIFICATION_FRAGMENT,
} from '../apollo.fragment'

export const SIGNIN_WORKER_MUTATION = gql`
	mutation signInWorker($recaptchaToken: String, $email: String!, $password: String!, $socialAuthenticationId: String) {
		signInWorker(recaptchaToken: $recaptchaToken, email: $email, password: $password, socialAuthenticationId: $socialAuthenticationId) {
			authToken {
				accessToken
				expiresIn
				refreshToken
				tokenType
			}
			notificationChannel
			worker {
				id
				workAuthorizedAt
				lastName
				firstName
				email
				user {
					changePasswordRequired
				}
			}
		}
	}
`

export const REFRESH_WORKER_TOKEN = gql`
	mutation($refreshToken: String!) {
		refreshToken(refreshToken: $refreshToken) {
			authToken {
				accessToken
				refreshToken
				tokenType
				expiresIn
			}
		}
	}
`

export const UPDATE_WORKER_PASSWORD = gql`
	mutation($password: String!) {
		updateProfile(password: $password) {
			id
		}
	}
`

export const RESET_PASSWORD_FOR_WORKER_MUTATION = gql`
	mutation($recaptchaToken: String, $token: String!, $email: String!, $password: String!, $passwordConfirmation: String!) {
		resetPasswordForWorker(recaptchaToken: $recaptchaToken, token: $token, email: $email, password: $password, passwordConfirmation: $passwordConfirmation) {
			success
		}
	}
`

export const VERIFY_OAUTH_LOGIN = gql`
	mutation verifyOauthLogin(
		$providerName: SocialProviderEnum!
		$clientId: String!
		$clientToken: String
		$email: String
		$code: String
		$redirectUri: String
		$recaptchaToken: String
	) {
		verifySocialAuthentication(
			providerName: $providerName
			clientId: $clientId
			clientToken: $clientToken
			email: $email
			code: $code
			redirectUri: $redirectUri
			recaptchaToken: $recaptchaToken
		) {
			authToken {
				accessToken
				refreshToken
			}
			emailHasAccount

			userSocialAuthentication {
				email
				providerName
				id
				providerUuid
				userId
			}
			worker {
				email
				firstName
				id
				lastName
				phone
				phoneVerifiedAt

				user {
					changePasswordRequired
				}
			}
		}
	}
`

export const CREATE_WORKER_MUTATION = gql`
	mutation(
		$recaptchaToken: String
		$firstName: String
		$lastName: String
		$email: String
		$phone: String
		$password: String
		$workerDisciplineSpecialties: [WorkerDisciplineSpecialtyInput!]
		$socialAuthenticationId: String
		$referredCode: String
	) {
		signUpWorker(
			recaptchaToken: $recaptchaToken
			firstName: $firstName
			lastName: $lastName
			email: $email
			phone: $phone
			password: $password

			workerDisciplineSpecialties: $workerDisciplineSpecialties
			socialAuthenticationId: $socialAuthenticationId
			referredCode: $referredCode
		) {
			authToken {
				accessToken
				refreshToken
				tokenType
				expiresIn
			}
			worker {
				id
				lastName
				firstName
				phone
				email
			}
			notificationChannel
		}
	}
`

export const UPDATE_WORKER_MUTATION = gql`
	mutation updateWorker($id: String!, $referral: String!) {
		updateIntegrationWorker(id: $id, referralMethod: $referral) {
			id
		}
	}
`

export const UPDATE_WORKER_LOCATION_MUTATION = gql`
	mutation createWorkingPreferredLocations($id: String!, $location1: String, $location2: String, $location3: String) {
		createWorkingPreferredLocations(
			id: $id
			workingPreferredLocationsAttributes: [{ preferredWorkingState: $location1 }, { preferredWorkingState: $location2 }, { preferredWorkingState: $location3 }]
		) {
			success
		}
	}
`

export const SEND_PASSWORD_RESET_WORKER_MUTATION = gql`
	mutation sendPasswordResetWorker($email: String!, $recaptchaToken: String!) {
		sendPasswordResetWorker(email: $email, recaptchaToken: $recaptchaToken) {
			success
		}
	}
`

export const RESET_PASSWORD_WORKER_MUTATION = gql`
	mutation resetPasswordWorker($token: String!, $password: String!, $passwordConfirmation: String!, $recaptchaToken: String) {
		resetPasswordForUser(recaptchaToken: $recaptchaToken, token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
			success
		}
	}
`

export const UPDATE_DISCIPLINES_SPECIALTIES_MUTATION = gql`
	mutation updateProfile($workerDisciplineSpecialties: [WorkerDisciplineSpecialtyInput!]) {
		updateProfile(workerDisciplineSpecialties: $workerDisciplineSpecialties) {
			id
			workerDisciplineSpecialties {
				id
				discipline {
					disciplineName
					disciplineType
					id
				}
				specialty {
					name
					id
				}
			}
		}
	}
`

export const UPDATE_EMERGENCY_CONTACTS_MUTATION = gql`
	mutation updateProfile($workerEmergencyContacts: [WorkerEmergencyContactInput!]) {
		updateProfile(workerEmergencyContacts: $workerEmergencyContacts) {
			id
			workerEmergencyContacts {
				firstName
				lastName
				phone
				relationship
			}
		}
	}
`

export const UPDATE_WORK_EXPERIENCE_MUTATION = gql`
	mutation updateWorkExperience($id: String!, $experiences: [WorkExperienceArgument!]) {
		updateIntegrationWorker(id: $id, workExperiencesAttributes: $experiences) {
			workExperiences {
				...workExperienceFragment
			}
		}
	}
	${WORK_EXPERIENCE_FRAGMENT}
`

export const UPDATE_WORKER_LICENSE_MUTATION = gql`
	mutation updateWorkerLicenses($id: String!, $licenses: [WorkerCertificationArgument!]) {
		updateIntegrationWorker(id: $id, workerCertificationsAttributes: $licenses) {
			id
			workerCertifications {
				...workerCertificationFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
`

export const CREATE_DIRECT_UPLOAD_MUTATION = gql`
	mutation createDirectUpload($filename: String!, $byteSize: Int!, $checksum: String!, $contentType: String!) {
		createDirectUpload(filename: $filename, byteSize: $byteSize, checksum: $checksum, contentType: $contentType) {
			...directUploadFragment
		}
	}
	${DIRECT_UPLOAD_FRAGMENT}
`

export const CREATE_WORKED_TIMESHEET_MUTATION = gql`
	mutation createWorkedTimesheet($timesheetImagesSignedBlobIds: [String!]!) {
		createWorkedTimesheet(timesheetImagesSignedBlobIds: $timesheetImagesSignedBlobIds, source: opus_web_portal) {
			...workedTimesheetFragment
			timesheetImages {
				...imageFileFragment
			}
		}
	}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const DESTROY_FILE_MUTATION = gql`
	mutation destroyDocument($id: String!) {
		destroyDocument(id: $id) {
			id
			success
		}
	}
`

export const UPDATE_WORKED_TIMESHEET_MUTATION = gql`
	mutation updateWorkedTimesheet($id: String!, $signedBlobId: [String!]!) {
		updateWorkedTimesheet(id: $id, signedBlobId: $signedBlobId) {
			...workedTimesheetFragment
			timesheetImages {
				...imageFileFragment
			}
		}
	}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`
export const UPDATE_WORKER_RESUME_MUTATION = gql`
	mutation($resumesSignedBlobIds: [String!]) {
		createOrUpdateWorkerResumes(resumesSignedBlobIds: $resumesSignedBlobIds) {
			resumes {
				id
				filename
				fileUrl
				contentType
				blobId
				imageUrls(size: ["100x100", "200x200", "500x500"])
			}
		}
	}
`

export const VERIFY_OTP_CODE_MUTATION = gql`
	mutation verifyOtpCodeMutation($otpCode: String!, $phoneNumber: String!, $recaptchaToken: String) {
		verifyOtpCode(otpCode: $otpCode, phoneNumber: $phoneNumber, recaptchaToken: $recaptchaToken) {
			success
		}
	}
`

export const UPDATE_WORKER_PREFER_LOCATION_MUTATION = gql`
	mutation UpdateWorkingPreferredLocations($workingPreferredLocationsAttributes: [WorkingPreferredLocationArgument!]) {
		updateWorkingPreferredLocations(workingPreferredLocationsAttributes: $workingPreferredLocationsAttributes) {
			id
			preferredWorkingCity
			preferredWorkingState
		}
	}
`

export const UPDATE_WORKER_DISPLINE_SPECIALTY_MUTATION = gql`
	mutation($workerDisciplineSpecialties: [WorkerDisciplineSpecialtyInput!]) {
		updateProfile(workerDisciplineSpecialties: $workerDisciplineSpecialties) {
			id
			workerDisciplineSpecialties {
				id
				discipline {
					disciplineName
					disciplineType
					id
				}
				specialty {
					name
					id
				}
			}
		}
	}
`

export const UPDATE_WORKER_INFO_MUTATION = gql`
	mutation(
		$firstName: String
		$lastName: String
		$email: String
		$preferredShifts: [String!]
		$preferredWorkTypes: [String!]
		$workingPreferredLocations: [WorkingPreferredLocationInput!]
		$addresses: [AddressInput!]
	) {
		updateProfile(
			firstName: $firstName
			lastName: $lastName
			email: $email
			preferredShifts: $preferredShifts
			preferredWorkTypes: $preferredWorkTypes
			workingPreferredLocations: $workingPreferredLocations
			addresses: $addresses
		) {
			id
			email
		}
	}
`

export const UPDATE_WORKER_INFO_AND_CONTACTS_MUTATION = gql`
	mutation(
		$firstName: String
		$lastName: String
		$email: String
		$preferredShifts: [String!]
		$preferredWorkTypes: [String!]
		$workingPreferredLocations: [WorkingPreferredLocationInput!]
		$addresses: [AddressInput!]
		$workerEmergencyContacts: [WorkerEmergencyContactInput!]
		$workerDisciplineSpecialties: [WorkerDisciplineSpecialtyInput!]
	) {
		updateProfile(
			firstName: $firstName
			lastName: $lastName
			email: $email
			preferredShifts: $preferredShifts
			preferredWorkTypes: $preferredWorkTypes
			workingPreferredLocations: $workingPreferredLocations
			addresses: $addresses
			workerEmergencyContacts: $workerEmergencyContacts
			workerDisciplineSpecialties: $workerDisciplineSpecialties
		) {
			id
			workerDisciplineSpecialties {
				id
				discipline {
					disciplineName
					disciplineType
					id
				}
				specialty {
					name
					id
				}
			}
		}
	}
`

export const CREATE_WORKER_CERTICATION_MUTATION = gql`
	mutation createWorkerCertification(
		$licenseSpecialty: String!
		$licenseState: String!
		$licenseNumber: String!
		$compactLicense: Boolean!
		$expirationDate: String!
		$certificationType: String!
		$licenseImages: [FileArgument!]!
	) {
		createWorkerCertification(
			licenseSpecialty: $licenseSpecialty
			licenseState: $licenseState
			licenseNumber: $licenseNumber
			compactLicense: $compactLicense
			expirationDate: $expirationDate
			certificationType: $certificationType
			licenseImages: $licenseImages
		) {
			...workerCertificationFragment
			licenseImages {
				...imageFileFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`
export const UPDATE_SHOW_PARTIAL_JOBS = gql`
	mutation updateIntegrationWorker($updateIntegrationWorkerId: String!, $settings: JSON) {
		updateIntegrationWorker(id: $updateIntegrationWorkerId, settings: $settings) {
			id
			settings
		}
	}
`

export const DISCONNECT_SOCIAL_AUTHENTICATION = gql`
	mutation DisconnectSocialAuthentication($socialAuthenticationId: String!) {
		disconnectSocialAuthentication(socialAuthenticationId: $socialAuthenticationId) {
			success
		}
	}
`
export const VERIFY_EXISTED_EMAIL = gql`
	mutation VerifyExistedEmail($email: String!, $recaptchaToken: String) {
		verifyExistedEmail(email: $email, recaptchaToken: $recaptchaToken) {
			existed
			existedInAtsMessage
		}
	}
`
export const SIGN_UP_WORKER_MUTATION = gql`
	mutation CreateIntegrationWorker(
		$firstName: String!
		$lastName: String!
		$email: String!
		$phone: String!
		$password: String!
		$passwordConfirmation: String!
		$recaptchaToken: String
		$referrerCode: String
	) {
		createIntegrationWorker(
			firstName: $firstName
			lastName: $lastName
			email: $email
			phone: $phone
			password: $password
			passwordConfirmation: $passwordConfirmation
			recaptchaToken: $recaptchaToken
			referrerCode: $referrerCode
		) {
			authToken
			notificationChannel
			worker {
				phoneVerifiedAt
				firstName
				lastName
				id
				email
				phone
			}
		}
	}
`

export const SUPPORT_DOCUMENTS_MUTATION = gql`
	query {
		supportDocuments {
			id
			documentType
			document {
				id
				fileUrl
				filename
				contentType
				blobId
				thumbnails
				imageUrls(size: ["100x100", "200x200", "500x500"])
			}
		}
	}
`

export const CREATE_OR_UPDATE_SUPPORT_DOCUMENTS_MUTATION = gql`
	mutation($supportDocumentsAttributes: [SupportDocumentArgument!]!) {
		createOrUpdateSupportDocument(supportDocumentsAttributes: $supportDocumentsAttributes) {
			id
			documentType
			document {
				id
				fileUrl
				filename
				contentType
				contentType
				blobId
				thumbnails
				imageUrls(size: ["100x100", "200x200", "500x500"])
			}
		}
	}
`

export const DELETE_WORKER_SPECIALTY = gql`
	mutation($id: String!) {
		destroyWorkerSpecialty(id: $id) {
			success
			id
		}
	}
`

export const CREATE_OR_UPDATE_WORKER_SPECIALTY = gql`
	mutation($workerSpecialtiesAttributes: [WorkerSpecialtyArgument!]!) {
		createOrUpdateWorkerSpecialty(workerSpecialtiesAttributes: $workerSpecialtiesAttributes) {
			id
			specialty
			completedChecklist
			category
			isPrimary
			specialtyLongName
			workerSkillChecklistSections {
				id
				name
				workerSkillChecklists {
					id
					question
					proficiency
					frequency
				}
			}
		}
	}
`

export const GET_WORKER_SPECIALTIES_QUERY = gql`
	query {
		workerSpecialties {
			id
			specialty
			completedChecklist
			isPrimary
			category
			specialtyLongName
			workerSkillChecklistSections {
				id
				name
				workerSkillChecklists {
					id
					question
					proficiency
					frequency
				}
			}
		}
	}
`

export const UPDATE_WORKER_PHONE = gql`
	mutation UpdateWorkerPhone($phone: String) {
		updateWorker(phone: $phone) {
			phone
			phoneVerifiedAt
		}
	}
`
export const UPDATE_WORKER_AVATAR = gql`
	mutation updateWorkerAvatar($avatar: DocumentInput) {
		updateProfile(avatar: $avatar) {
			id
			avatar {
				id
				file {
					thumbnails
				}
			}
		}
	}
`

export const SEND_OTP_CODE_MUTATION = gql`
	mutation sendOtpCodeMutation($phoneNumber: String!, $recaptchaToken: String) {
		sendOtpCode(phoneNumber: $phoneNumber, recaptchaToken: $recaptchaToken) {
			success
			remainingSessionResendCode
			remainingTotalResendCode
		}
	}
`

export const CONFIRM_CHANGE_EMAIL_MUTATION = gql`
	mutation($confirmationToken: String!) {
		confirmChangeEmail(confirmationToken: $confirmationToken) {
			success
			workerId
		}
	}
`
export const ACCEPT_TERM_OF_SERVICE_MUTATION = gql`
	mutation acceptTermsofServiceMutation($id: String!) {
		acceptTermsOfService(id: $id) {
			id
		}
	}
`
export const FAVORIE_UNFAVORITE_JOBS_MUTATION = gql`
	mutation favoriteOrUnfavoriteJobMutation($jobId: String!, $action: ActsAsFavoritorActionEnum!) {
		favoriteOrUnfavoriteJob(jobId: $jobId, action: $action) {
			...jobDetailFragment
		}
	}
	${JOB_DETAIL_FRAGMENT}
`
