import { TIME_12_FORMAT_VIEW } from '../constants'

const moment = require('moment-timezone')

export function getFormatDateTime(timestamp, timezone) {
	if (timestamp) {
		const timezoneConvert = moment.tz(timestamp, timezone)
		const formattedDate = timezoneConvert.format('dddd, MMM D, YYYY')
		return formattedDate
	}
}

export function getFormatTimedata(startTime, endTime, timezone) {
	if (startTime && endTime) {
		// Parse the timestamps and set the timezone
		const start = moment.tz(startTime, timezone)
		const end = moment.tz(endTime, timezone)
		// Format the output string
		const formattedStartTime = start.format('h:mmA')
		const formattedEndTime = end.format('h:mmA')

		return `${formattedStartTime}-${formattedEndTime}`
	}

	return null // Handle case where either startTime or endTime is missing
}

export function getFormatTime(timestamp, timezone) {
	if (timestamp) {
		const timezoneConvert = moment.tz(timestamp, timezone)
		const formattedTime = timezoneConvert.format('hh:mm a')
		return formattedTime
	}
}

export function calculateDuration(startTime, endTime) {
	if (startTime && endTime) {
		const duration = moment.duration(moment(endTime).diff(moment(startTime)))

		const hours = Math.floor(duration.asHours())
		const minutes = Math.floor(duration.asMinutes()) % 60

		const formattedDuration = `${hours}:${minutes.toString().padStart(2, '0')}`
		return formattedDuration
	}
}

export const formatDatetimePerdiem = (datetime, timezone) => {
	if (datetime) {
		return moment.tz(datetime, timezone).format(TIME_12_FORMAT_VIEW)
	}
}

export const formatDatePerdiem = (datetime, timezone) => {
	if (datetime) {
		return moment.tz(datetime, timezone).format('MMM D, YYYY')
	}
}

export const formatDateSymlrWithoutLST = (datetime, timezone) => {
	if (datetime) {
		return moment.tz(datetime, timezone).format('MMM D, YYYY')
	}
}

export const formatDateTravel = (datetime, timezone) => {
	if (datetime) {
		return moment.tz(datetime, timezone).format('MMM D, YYYY')
	}
}

export function isCurrentTimeAfterScheduledEnd(scheduledEndTime, timezone = 'America/New_York', days = 3) {
	// Parse the scheduled end time
	let scheduledEndMoment = moment(scheduledEndTime).tz('America/New_York')

	// Add three days to the scheduled end time
	scheduledEndMoment.add(3, 'days')

	// Set the time to 9:30 AM on the day scheduledEndTime + 3 days
	scheduledEndMoment.set({ hour: 9, minute: 30, second: 0, millisecond: 0 })

	// Get the current time in EST
	let currentTime = moment().tz('America/New_York')

	// Check if current time is after the scheduled end time + 3 days and after 9:30 AM
	if (currentTime.isAfter(scheduledEndMoment)) {
		return true
	} else {
		return false
	}
}
export function convertToTimeZone(timezone) {
	// Parse start and end dates in UTC
	if (timezone) {
		const currentDate = moment().tz(timezone)
		const previousDate = moment().tz(timezone).subtract(1, 'day')

		const scheduledStartTimeBetween = [previousDate.startOf('day').toISOString(), currentDate.endOf('day').toISOString()]

		return scheduledStartTimeBetween
	}
}
