export const PATHS = {
	// COMMON
	common: {
		home: '/',
		reset: '/reset',
		signin: '/signin',
		errors: '/errors',
		change_password: '/change_password',
		api: '/api',
		public_graphql: '/api/graphql/public',
		candidate_graphql: '/api/graphql/candidate',
		signup: '/signup',
		tracking: '/tracking',
		link_account: '/link_account',
		link_options: '/link_options',
		authorization: '/authorization',
		update_phone: '/update_phone',
		update_email_confirmation: '/update_email_confirmation',
		notifications: '/notifications',
		privacy_policy: '/privacy_policy',
		maintenance: '/maintenance',
		verify_credential: '/verify-credential',
	},
	auth: {
		linkedin: '/linkedin',
	},

	// CARE
	care: {
		home: '/home',
		signup: '/signup',
		profile: '/profile',
		find_job: '/find_job',
		my_jobs: '/my_jobs',
		credential: '/credential',
		activation: '/activation',
		timecards: '/timecards',
		benefits: '/benefits',
		popular_cities: '/popular_cities',
		popular_cities_detail: '/popular_cities_detail',
		urgent_needs: '/hot_jobs',
		suggested_jobs: '/recommended_jobs',
		trending_jobs: '/trending_jobs',
		discover: '/discover',
		jobs: '/jobs',
		refer_traveler: '/refer_traveler',
		referrer: '/referrer',
		contact_us: '/contact_us',
		contact_recruiter: '/contact_recruiter',
		settings: '/settings',
		messages: '/messages',
		filter: '/filter',
		edit_filter: '/edit_filter',
		search: '/search',
		last_viewed: '/last_viewed',
		bookmark: '/bookmark',
		job_alerts: '/job_alerts',
		badges: '/my-badges',
		credentials: '/credentials',
		privacy_policy: '/privacy_policy',
		available_perdiem_shift: '/available_perdiem_shift',
	},
	banners: {
		first: '/assets/common/banner-1.png',
		second: '/assets/common/banner-2.png',
		third: '/assets/common/banner-3.png',
		four: '/assets/common/banner-4.png',
	},

	worker_portal_prd: 'worker-portal-prd.opusapps.io',
	worker_portal_qa: 'worker-portal-qa.opusasia.io',
	worker_portal_stg: 'worker-portal-stg.opusasia.io',

	// CARE OUTSIDE LINKS
	outside_care: {
		travel_jobs: 'https://www.hosthealthcare.com/travel-jobs/',
		programmaticUrl: 'https://ad.ipredictive.com/d/track/cvt/pixel?acct_id=[trackingid]&cache_buster=[timestamp]',
		conversionPixelUrl: 'https://tags.srv.stackadapt.com/conv?cid=[conversionid]',
		exclusionPixelUrl: 'https://tags.srv.stackadapt.com/rt?sid=[exclusionid]',
		signup: 'https://www.hosthealthcare.com/sign-up',
	},
}
