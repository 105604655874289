import axios from 'axios'
import { authStore } from '~/stores'

export const createAxios = (headers) => {
	return axios.create({
		baseURL: process.env.REACT_APP_TRACKING_API_URL,
		headers: {
			'x-api-key': process.env.REACT_APP_TRACKING_API_KEY,
			...headers,
		},
		method: 'PUT',
		timeout: 30000,
	})
}

const records = async (dataValue) => {
	const { data, name } = dataValue || {}
	const { ui_key, component_name, location, session_id, ...rest } = data || {}
	const anonymousId = await window.host.getId()
	const ip = await window.host.getIP()
	const platform = await window.host.getBrowserPlatform()
	const platform_version = await window.host.getBrowserVersion()
	const userAgent = await window.host.getUserAgent()
	const timezone = await window.host.getTimezone()
	const timestamp = +Math.floor(Date.now() / 1000)
	try {
		const trackingData = {
			Records: [
				{
					data: {
						common_properties: {
							anonymous_id: anonymousId, // For Anonymous Tracking Flow
							user_id: authStore.id, // For Signed-in Tracking Flow
							app_build_version: null, // OPTIONAL
							app_bundle: null, // OPTIONAL
							app_version: null, // OPTIONAL
							device_id: anonymousId,
							ip: ip,
							location: location, // OPTIONAL: lat,lon
							platform: platform, // Follow User Agent Standards
							platform_version: platform_version, // Follow User Agent Standards
							user_agent: userAgent,
						},

						event_properties: {
							...rest,
						},
						misc_properties: {
							component_name: component_name, // Example in Section [6.2] - https://mylong.atlassian.net/wiki/spaces/ODS/pages/263979011/Trackings#SCREEN-Tracking
							key: ui_key, // Expandable key-value pairs,
							session_id: session_id,
						},
					},
					tenant_id: process.env.REACT_APP_COMPANY_ID, // equivalent to LEGACY company_id
					source: 'opus_web_portal', // opus_web_portal, opus_mobile_app, ghr_web_portal, lsr_web_portal, etc.
					is_anonymous: !authStore.id,
					timestamp: timestamp, // Unix Epoch time in seconds UTC
					timezone: timezone, // Asia/Ho_Chi_Minh
					name: name, // Event Name
				},
			],
		}
		if (authStore.id) {
			return await createAxios().put('/records', trackingData)
		} else {
			return await createAxios().put('/anonymous', trackingData)
		}
	} catch (error) {
		console.log('error', error)
		return null
	}
}

export const httpRequestTracking = {
	record: records,
}
