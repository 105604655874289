import { gql } from '@apollo/client'
import { apolloClient } from '~/common/apollo'
import { WORKER_CERTIFICATION_FRAGMENT, WORKER_EDUCATION_FRAGMENT, WORKER_REFERENCE_FRAGMENT, WORK_EXPERIENCE_FRAGMENT } from '~/common/apollo/apollo.fragment'
import { mutateWithTrimmedVariables } from '~/common/helpers'

export const GET_WORKER_CERTIFICATIONS_QUERY = gql`
	query getWorkerCertifications($workerId: String!) {
		workerCertifications(workerId: $workerId) {
			...workerCertificationFragment
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
`

export const CREATE_WORKER_CERTIFICATION_MUTATION = gql`
	mutation createWorkerCertificationMutation(
		$title: String
		$licenseSpecialty: String
		$licenseState: String
		$licenseNumber: String
		$compactLicense: Boolean
		$expirationDate: ISO8601Date
		$certificationType: String!
		$licenseImagesSignedBlobIds: [String!]
	) {
		createWorkerCertification(
			title: $title
			certificationType: $certificationType
			licenseSpecialty: $licenseSpecialty
			licenseState: $licenseState
			licenseNumber: $licenseNumber
			compactLicense: $compactLicense
			expirationDate: $expirationDate
			licenseImagesSignedBlobIds: $licenseImagesSignedBlobIds
		) {
			...workerCertificationFragment
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
`

export const UPDATE_WORKER_CERTIFICATION_MUTATION = gql`
	mutation UpdateWorkerCertification(
		$certificationType: String!
		$id: String
		$title: String
		$licenseSpecialty: String
		$licenseState: String
		$licenseNumber: String
		$expirationDate: ISO8601Date
		$licenseImages: [FileArgument!]
		$signedBlobId: String
		$signedBlobIds: [String!]
	) {
		updateWorkerCertification(
			certificationType: $certificationType
			id: $id
			title: $title
			licenseSpecialty: $licenseSpecialty
			licenseState: $licenseState
			licenseNumber: $licenseNumber
			expirationDate: $expirationDate
			licenseImages: $licenseImages
			signedBlobId: $signedBlobId
			signedBlobIds: $signedBlobIds
		) {
			certificationType
			compactLicense
			createdAt
			expirationDate
			id
			licenseSpecialty
			licenseImages {
				id
				filename
				fileUrl
				contentType
			}
			licenseNumber
			licenseState
			title
		}
	}
`

export const CREATE_WORKER_REFERENCE_MUTATION = gql`
	mutation createWorkerReferenceMutation($workerReferences: [WorkerReferenceInput!]) {
		updateProfile(workerReferences: $workerReferences) {
			workerReferences {
				...workerReferenceFragment
			}
		}
	}
	${WORKER_REFERENCE_FRAGMENT}
`

export const GET_WORKER_REFERENCES_QUERY = gql`
	query getWorkerReferencesQuery {
		worker {
			workerReferences {
				...workerReferenceFragment
			}
		}
	}

	${WORKER_REFERENCE_FRAGMENT}
`
export const UPDATE_PROFILE_EDUCATION_MUTATION = gql`
	mutation updateProfile($workerEducations: [WorkerEducationInput!]) {
		updateProfile(workerEducations: $workerEducations) {
			id
		}
	}
`

export const UPDATE_WORKER_EDU_MUTATION = gql`
	mutation UpdateWorkerEducation(
		$updateWorkerEducationId: String
		$schoolName: String
		$major: String
		$city: String
		$state: String
		$country: String
		$degreeName: String
		$graduationDate: ISO8601Date
	) {
		updateWorkerEducation(
			id: $updateWorkerEducationId
			schoolName: $schoolName
			major: $major
			city: $city
			state: $state
			country: $country
			degreeName: $degreeName
			graduationDate: $graduationDate
		) {
			city
			createdAt
			graduationDate
			degreeName
			id
			major
			schoolName
			state
		}
	}
`

export const UPDATE_WORKER_CREDENTIAL_MUTATION = gql`
	mutation updateWorkerCredentialMutation(
		$id: String
		$number: String
		$files: [DocumentInput!]
		$expirationDate: ISO8601Date
		$effectiveDate: ISO8601Date
		$compactState: Boolean
		$state: String
	) {
		updateWorkerCredential(
			id: $id
			number: $number
			files: $files
			expirationDate: $expirationDate
			effectiveDate: $effectiveDate
			compactState: $compactState
			state: $state
		) {
			id
		}
	}
`

export const UPDATE_WORKER_REFERENCE_MUTATION = gql`
	mutation UpdateWorkerReference($workerReferences: [WorkerReferenceInput!]) {
		updateProfile(workerReferences: $workerReferences) {
			workerReferences {
				...workerReferenceFragment
			}
		}
	}
	${WORKER_REFERENCE_FRAGMENT}
`

export const UPDATE_WORKER_EXPERIENCE_MUTATION = gql`
	mutation UpdateWorkExperience(
		$id: String
		$facilityName: String
		$title: String
		$agency: String
		$chartingSoftwareUsed: String
		$unitType: String
		$startDate: ISO8601Date
		$endDate: ISO8601Date
		$currentlyEmployed: Boolean
		$city: String
		$state: String
		$country: String
		$reasonForLeaving: String
		$travelAssignment: Boolean
		$notes: String
		$gapReason: String
		$gapStartDate: ISO8601Date
		$gapEndDate: ISO8601Date
		$reasonForLeavingNote: String
		$gapInEmployment: Boolean
	) {
		updateWorkExperience(
			id: $id
			facilityName: $facilityName
			title: $title
			agency: $agency
			chartingSoftwareUsed: $chartingSoftwareUsed
			unitType: $unitType
			startDate: $startDate
			endDate: $endDate
			currentlyEmployed: $currentlyEmployed
			city: $city
			state: $state
			country: $country
			reasonForLeaving: $reasonForLeaving
			travelAssignment: $travelAssignment
			gapReason: $gapReason
			gapStartDate: $gapStartDate
			gapEndDate: $gapEndDate
			reasonForLeavingNote: $reasonForLeavingNote
			notes: $notes
			gapInEmployment: $gapInEmployment
		) {
			id
		}
	}
`

export const UPDATE_WORKER_CER_MUTATION = gql`
	mutation UpdateWorkerCertification(
		$certificationType: String!
		$updateWorkerCertificationId: String
		$title: String
		$licenseSpecialty: String
		$licenseState: String
		$licenseNumber: String
		$compactLicense: Boolean
		$expirationDate: ISO8601Date
		$licenseImages: [FileArgument!]
		$licenseImagesSignedBlobIds: [String!]
	) {
		updateWorkerCertification(
			certificationType: $certificationType
			id: $updateWorkerCertificationId
			title: $title
			licenseSpecialty: $licenseSpecialty
			licenseState: $licenseState
			licenseNumber: $licenseNumber
			compactLicense: $compactLicense
			expirationDate: $expirationDate
			licenseImages: $licenseImages
			licenseImagesSignedBlobIds: $licenseImagesSignedBlobIds
		) {
			certificationType
			compactLicense
			createdAt
			expirationDate
			id
			licenseSpecialty
			licenseImages {
				blobId
				fileUrl
				filename
				id
				contentType
			}
			licenseNumber
			licenseState
			title
		}
	}
`

export const DESTROY_WORKER_EDU_MUTATION = gql`
	mutation DestroyWorkerEducation($destroyWorkerEducationId: String!) {
		destroyWorkerEducation(id: $destroyWorkerEducationId) {
			id
			success
		}
	}
`

export const DESTROY_WORKER_REFERENCE_MUTATION = gql`
	mutation DestroyWorkerReference($destroyWorkerReferenceId: String!) {
		destroyWorkerReference(id: $destroyWorkerReferenceId) {
			id
			success
		}
	}
`

export const DESTROY_WORKER_CER_MUTATION = gql`
	mutation DestroyWorkerCertification($destroyWorkerCertificationId: String!) {
		destroyWorkerCertification(id: $destroyWorkerCertificationId) {
			id
			success
		}
	}
`

export const DESTROY_WORKER_EXPERIENCE_MUTATION = gql`
	mutation DestroyWorkExperience($destroyWorkExperienceId: String!) {
		destroyWorkExperience(id: $destroyWorkExperienceId) {
			id
			success
		}
	}
`

export const GET_WORK_EXPERIENCES_QUERY = gql`
	query getWorkExperiencesQuery {
		worker {
			workExperiences {
				...workExperienceFragment
			}
		}
	}

	${WORK_EXPERIENCE_FRAGMENT}
`

export const GET_WORKER_EDUCATIONS_QUERY = gql`
	query getWorkerEducations {
		workerEducations {
			...workerEducationFragment
		}
	}

	${WORKER_EDUCATION_FRAGMENT}
`

export const GET_WORKER_CREDENTIALS_QUERY = gql`
	query getWorkerCredentialsQuery {
		workerCredentials {
			id
			esignUrl
			requiredFields
			isLicense
			requiredVisibleFields
			credential {
				enableCompactOrMultiState
				completeProfileSkillChecklistUrl
				id
				category
				atsSource
				default
				name
				completeType
				credentialCompleteUrl
				isLicense
				fileSample {
					id
					file {
						fileUrl
					}
				}
			}
			files {
				id
				file {
					id
					fileUrl
					filename
					contentType
				}
			}
			compactState
			credentialName
			effectiveDate
			expirationDate
			number
			status
			state
		}
	}
`

export const CREATE_WORKER_EDUCATION_MUTATION = gql`
	mutation createWorkerEducationMutation(
		$schoolName: String
		$major: String
		$state: String
		$city: String
		$country: String
		$degreeName: String
		$graduationDate: ISO8601Date
	) {
		createWorkerEducation(
			schoolName: $schoolName
			major: $major
			state: $state
			city: $city
			country: $country
			degreeName: $degreeName
			graduationDate: $graduationDate
		) {
			...workerEducationFragment
		}
	}

	${WORKER_EDUCATION_FRAGMENT}
`

export const CREATE_WORK_EXPERIENCE_MUTATION = gql`
	mutation updateWorkExperienceMutation($workExperiences: [WorkExperienceInput!]) {
		updateProfile(workExperiences: $workExperiences) {
			workExperiences {
				id
			}
		}
	}
`

export const UPDATE_WORKER_RESUME_MUTATION = gql`
	mutation($resumes: [DocumentInput!]) {
		updateProfile(resumes: $resumes) {
			id
		}
	}
`

export const MARK_CREDENTIAL_AS_SIGNED = gql`
	mutation markSignedWorkerCredentialMutation($id: String!) {
		markSignedWorkerCredential(id: $id) {
			success
		}
	}
`

export const createWorkExperience = async (workerId, variables) => {
	await mutateWithTrimmedVariables({
		mutation: CREATE_WORK_EXPERIENCE_MUTATION,
		variables: { id: workerId, workExperiences: [variables] },
	})

	return variables
}

export const createWorkerEducation = async (variables) => {
	const response = await mutateWithTrimmedVariables({ mutation: CREATE_WORKER_EDUCATION_MUTATION, variables })

	return response?.data?.createWorkerEducation
}
export const createWorkerCredentials = async (variables) => {
	const response = await mutateWithTrimmedVariables({ mutation: CREATE_WORKER_EDUCATION_MUTATION, variables })

	return response?.data?.createWorkerEducation
}

export const getWorkerEducations = async () => {
	const response = await apolloClient.query({ query: GET_WORKER_EDUCATIONS_QUERY })

	return response?.data?.workerEducations
}

export const getWorkerCredentials = async () => {
	const response = await apolloClient.query({ query: GET_WORKER_CREDENTIALS_QUERY })

	return response?.data?.workerCredentials
}

export const getWorkExperiences = async (workerId) => {
	const response = await apolloClient.query({ query: GET_WORK_EXPERIENCES_QUERY, variables: { workerId } })

	return response.data?.worker?.workExperiences
}

export const getWorkerReferences = async (workerId) => {
	const response = await apolloClient.query({ query: GET_WORKER_REFERENCES_QUERY, variables: { workerId } })

	return response.data?.worker?.workerReferences
}

export const createWorkerReference = async (variables) => {
	const response = await mutateWithTrimmedVariables({ mutation: CREATE_WORKER_REFERENCE_MUTATION, variables })

	return response.data?.createWorkerReference
}

export const getWorkerCertifications = async (workerId) => {
	const response = await apolloClient.query({ query: GET_WORKER_CERTIFICATIONS_QUERY, variables: { workerId } })

	return response?.data?.workerCertifications
}

export const createWorkerCertification = async (variables) => {
	const response = await mutateWithTrimmedVariables({ mutation: CREATE_WORKER_CERTIFICATION_MUTATION, variables })

	return response?.data?.createWorkerCertification
}

export const updateWorkerCertification = async (variables) => {
	const response = await mutateWithTrimmedVariables({ mutation: UPDATE_WORKER_CERTIFICATION_MUTATION, variables }).then(() => {
		window.location.reload()
	})
	return response?.data?.updateWorkerCertification
}

export const markCredentialAsSigned = async (id) => {
	const response = await apolloClient.mutate({
		mutation: MARK_CREDENTIAL_AS_SIGNED,
		variables: {
			id,
		},
	})
	return response?.data?.markSignedWorkerCredential?.success
}
