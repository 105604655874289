import { gql } from '@apollo/client'
import { JOB_FRAGMENT, WORKER_FRAGMENT } from '../apollo.fragment'

export const GET_CURRENT_WORKER_ASSIGNMENT_QUERY = gql`
	query {
		currentWorkerAssignment {
			id
		}
	}
`

export const GET_WORKER_CURRENT_JOB_QUERY = gql`
	query CurrentWorkerAssignment {
		currentWorkerAssignment {
			job {
				applicantCount
				createdAt
				description
				discipline
				duration
				exclusiveJob
				externalCreatedAt
				externalJobId
				externalUpdatedAt
				facilityType
				favoriteCount
				frequency
				hoursPerWeek
				id
				isDisliked
				isLiked
				isSkillChecklist
				jobType
				matchingPercentage
				minWeeklyPayAmount
				shifts
				skillLongName
				slotsNumber
				specialty
				startDate
				status
				title
				updatedAt
				viewCount
				weeklyPayAmount
			}
		}
	}
`

export const GET_WORKER_PAST_JOB_QUERY = gql`
	query getWorkerPastJobQuery($offset: Int, $limit: Int) {
		workerAssignments(offset: $offset, limit: $limit) {
			availableToExtend
			id
			status
			createdAt
			updatedAt
			startDate
			endDate
			job {
				...jobFragment
			}
			worker {
				...workerFragment
			}
		}
	}

	${JOB_FRAGMENT}
	${WORKER_FRAGMENT}
`

export const GET_JOB_DETAIL_QUERY = gql`
	query getJobDetailQuery($id: String!) {
		workerAssignment(id: $id) {
			availableToExtend
			id
			status
			createdAt
			updatedAt
			startDate
			endDate
			job {
				...jobFragment
			}
			worker {
				...workerFragment
			}
		}
	}

	${JOB_FRAGMENT}
	${WORKER_FRAGMENT}
`

export const GET_JOB_APPLICANT_QUERY = gql`
	query getJobApplicantQuery($id: String!) {
		jobApplicant(id: $id) {
			id
		}
	}
`

export const GET_LOOK_UP_SKILL_CHECKLISTS_QUERY = gql`
	query getLookupSkillChecklistsByJob($jobId: String!) {
		lookupSkillChecklistsByJob(jobId: $jobId) {
			id
			name
			provider
			providerId
			workerSkillChecklist {
				assignmentUrl
				completed
				completedAt
				createdAt
				expirationAt
				expired
				expiredWorkerSclId
				externalId
				id
				name
				score
			}
		}
	}
`

export const GET_LOOK_UP_ACCOUNT_SCL_QUERY = gql`
	query getLookupAccountScl($recaptchaToken: String, $email: String!) {
		lookupAccountScl(recaptchaToken: $recaptchaToken, email: $email) {
			provider
			providerId
		}
	}
`

export const GET_WORKER_ACCOUNT_SCL_QUERY = gql`
	query fetchWorkerSclProvider($recaptchaToken: String, $providerAccount: String!) {
		fetchWorkerSclProvider(recaptchaToken: $recaptchaToken, providerAccount: $providerAccount) {
			workerSkillChecklists
		}
	}
`

export const GET_ASSIGNMENT_JOB__QUERY = gql`
	query getJobDetailQuery($id: String!) {
		workerAssignment(id: $id) {
			id
			availableToExtend
			job {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_JOB_DETAIL = gql`
	query getJobDetail($id: String!) {
		job(id: $id) {
			...jobFragment
		}
	}
	${JOB_FRAGMENT}
`

export const CALENDAR_VIEW_QUERY = gql`
	query calendarViewPerDiem($fromDate: ISO8601Date!, $toDate: ISO8601Date!) {
		calendarViewPerDiem(fromDate: $fromDate, toDate: $toDate) {
			date
			data {
				nonLongTermPerDiemJobsCount
				workerAssignments {
					acceptedAt
					actualEndTime
					actualStartTime
					availableToExtend
					customFields
					externalId
					id
					isCancelled
					isExtend
					note
					permittedActions
					reasonCancelled
					scheduledEndTime
					scheduledStartTime
					shiftTypes
					status
					job {
						...jobFragment
					}
				}
			}
		}
	}
	${JOB_FRAGMENT}
`

export const GET_JOB_PAY_RATE = gql`
	query fetchJobPayRate($jobId: String!) {
		fetchJobPayRate(jobId: $jobId) {
			payRate
			payType
		}
	}
`
