import { apolloClient, DOWNLOAD_RESULT_FILE, GET_WORKER_SKILL_CHECKLISTS_QUERY, FETCH_UNA_LINK_MUTATION } from '~/common/apollo'
import { store, observable, action, computed } from '~/common/mobx.decorator'

@store()
class ChecklistsStore {
	@observable checklists = []
	@observable pageInfo
	@observable resultFile = {}
	@observable unaLink = ''

	@computed
	get shouldConfirmCreateUnaAccount() {
		return (this.workerBasicInfo?.customField1 === 'New Lead' || this.workerBasicInfo?.customField1 === 'Prospect') && !this.sclProvider
	}

	@computed
	get hasNextPage() {
		return this.pageInfo?.hasNextPage || false
	}

	@computed
	get endCursor() {
		return this.pageInfo?.endCursor
	}

	@action
	resetFetchedData = () => {
		this.checklists = []
		this.pageInfo = null
	}

	@action
	downloadResultFile = async (id) => {
		const { data } = await apolloClient.query({
			query: DOWNLOAD_RESULT_FILE,
			variables: {
				id,
			},
		})
		return data?.workerSkillChecklist?.resultFile?.file
	}

	@action
	getUnaLink = async () => {
		const { data } = await apolloClient.mutate({
			mutation: FETCH_UNA_LINK_MUTATION,
		})
		this.unaLink = data?.fetchSclAssignmentLink?.assignmentLink || ''
	}

	@action
	refetchChecklists = async () => {
		this.resetFetchedData()
		await this.fetchChecklists()
	}

	@action
	fetchChecklists = async () => {
		const response = await apolloClient.query({
			query: GET_WORKER_SKILL_CHECKLISTS_QUERY,
			variables: {
				first: 20,
				...(this.endCursor && { after: this.endCursor }),
			},
		})

		if (!response?.data) {
			return
		}

		this.checklists = [...this.checklists, ...(response.data?.workerSkillChecklists?.nodes ?? [])]
		this.pageInfo = response.data?.workerSkillChecklists?.pageInfo
	}
}

export const checklistsStore = new ChecklistsStore()
