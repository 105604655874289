import React, { useEffect, useRef } from 'react'
import { Redirect, Route as BaseRoute, Switch, useLocation } from 'react-router-dom'
import { observer } from '~/common/mobx.decorator'
import { Route } from './components/route'
import { FEATURES } from '~/features'
import { COMPANIES } from '~/companies'
import { PATHS } from './common/constants'
import { Tracking } from './tracking'
import { careStore } from '~/companies/care/care.store'
import { isEmpty } from 'lodash'
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import { logTrackingScreenEvent } from '~/common/tracking/event-client.tracking'
import { generateUUID } from '~/common/helpers/uid.helper'
import { eventClient } from '@opus/web.core.lib.event-tracking'
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs.js'
import { getSessionId, createSessionId, removeSessionId, initializeSessionId } from '~/common/helpers/session.helper'

export const AppRoute = observer(() => {
	let location = useLocation()
	const idleTimerRef = useRef(null)

	const startSession = () => {
		if (!getSessionId()) {
			createSessionId()
		}
	}

	const endSession = () => {
		removeSessionId()
	}

	const handleOnIdle = () => {
		if (document?.hidden) {
			endSession()
		}
	}

	const handleOnAction = () => {
		startSession()
	}

	useEffect(() => {
		initializeSessionId()
	}, [])

	React.useEffect(() => {
		const code = careStore.allFeatures?.code
		async function loadScreenKeys() {
			if (!isEmpty(code)) {
				await eventClient.setCurrentComponentName(code)
				await eventClient.setCurrentUIKey(generateUUID())
				await logTrackingScreenEvent(code, { is_start_screen_session: true })
			} else {
				await eventClient.setCurrentComponentName('')
				await eventClient.setCurrentUIKey('')
				await eventClient.setCurrentSessionId('')
			}
		}
		loadScreenKeys()
	}, [location])

	useIdleTimer({
		ref: idleTimerRef,
		timeout: 15 * 60 * 1000,
		onIdle: handleOnIdle,
		onAction: handleOnAction,
		debounce: 500,
	})

	return (
		<Switch>
			<BaseRoute
				exact
				sensitive
				path="/:url([a-z/]*[A-Z]+[a-z/]*)/"
				render={(props) => <Redirect to={{ pathname: `${props.location.pathname}`.toLowerCase() }} />}
			/>
			<Route path={PATHS.common.maintenance} component={FEATURES['common-maintenance']} />
			<Route path={PATHS.common.tracking} component={Tracking} />
			<Route path={PATHS.common.signin} component={FEATURES['signin']} />
			<Route path={PATHS.common.signup} component={FEATURES['signup']} />
			<Route path={PATHS.common.errors} component={FEATURES['errors']} />
			<Route path={PATHS.common.authorization} component={FEATURES['authorization']} />
			<Route path={PATHS.common.reset} component={FEATURES['reset']} />
			<Route path={PATHS.common.link_account} component={FEATURES['link-account']} />
			<Route path={PATHS.common.link_options} component={FEATURES['link-options']} />
			<Route path={PATHS.common.privacy_policy} component={FEATURES['privacy-policy']} />
			<Route path={PATHS.common.verify_credential} component={FEATURES['verify-credential']} />
			<Route path={PATHS.common.update_email_confirmation} component={FEATURES['update-email-confirmation']} />
			<Route exact path={PATHS.auth.linkedin} component={LinkedInCallback} />
			{/* <Route auth={true} path={PATHS.common.change_password} component={FEATURES['common-change-password']} /> */}
			<Route path={PATHS.common.home} component={COMPANIES.care} />
		</Switch>
	)
})
