import { eventClient } from '@opus/web.core.lib.event-tracking'
import { convertKeysToSnakeCase } from '~/common/helpers/mapping.helper'

const logActionEvent = (eventId, actionType, eventParams) => {
	eventClient.logActionEvent(eventId, actionType, eventParams).catch((error) => console.log(`Error tracking data ${eventId}`, error.message))
}

export const logClickEvent = (eventId, eventParams) => {
	logActionEvent(eventId, 'click', eventParams)
}

export const logTrackingScreenEvent = (screenName, eventParams) => {
	logActionEvent(screenName, 'screen', eventParams)
}

export const logDefaultActionEvent = (screenName, eventParams) => {
	logActionEvent(screenName, 'default', eventParams)
}

export const logClickLike = (eventType, jobId, listType) => {
	logClickEvent(eventType, {
		list_type: listType,
		job_id: jobId,
	})
}

export const logClickDisLike = (eventType, jobId, listType) => {
	logClickEvent(eventType, {
		list_type: listType,
		job_id: jobId,
	})
}

export const logClickShare = (eventType, jobId, listType) => {
	logClickEvent(eventType, {
		list_type: listType,
		job_id: jobId,
	})
}

export const logClickSignUpToViewDetail = (eventType, jobId, listType) => {
	logClickEvent(eventType, {
		list_type: listType,
		job_id: jobId,
	})
}

async function getLocation() {
	try {
		if (window.host_location) {
			return window.host_location
		}

		const response = await fetch('https://ipinfo.io/json')
		const data = await response.json()
		window.host_location = data

		if (data.error) {
			return {}
		}

		return data
	} catch (error) {
		console.debug(error)
	}
}

export const getUserTrackingBasicInfor = async () => {
	return new Promise(async (resolve) => {
		const location = await getLocation()
		const infor = {
			location,
		}

		return resolve(convertKeysToSnakeCase(infor))
	})
}
